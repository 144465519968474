<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <Table
          ref="table"
          @close_form="close_form()"
          :data_loaded="data_loaded"
          :items="coupons"
          @data_loaded="data_loaded = true"
          @update_data="get_data()"
          :page="page"
          :total="total"
          @updatePage="updatePage($event)"
        ></Table>

        <v-btn
          elevation="8"
          class="mt-5"
          v-if="
            !form && data_loaded && $store.getters.permission('coupons create')
          "
          @click="form = !form"
          fab
          small
        >
          <v-icon>add</v-icon>
        </v-btn>
        <Form
          @update_table="update_table()"
          @close_form="form = !form"
          v-if="form && $store.getters.permission('coupons create')"
        ></Form>
      </div>
    </div>
  </v-container>
</template>

<script>
import Table from "@/components/coupons/table";
import Form from "@/components/coupons/form";
export default {
  name: "coupons",
  data() {
    return {
      form: false,
      data_loaded: false,
      coupons: [],
      page: 1,
      total: 0,
    };
  },
  components: {
    Table,
    Form,
  },

  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `coupons?page=${this.page}`,
            method: "get",
          },
        })
        .then((res) => {
          const data = res.data.data;
          this.coupons = Object.assign([], data.data);
          this.total = data.meta.last_page;
          this.page = data.meta.current_page;
          this.data_loaded = true;
        });
    },
    updatePage(page) {
      this.page = page;
      this.get_data();
    },
    update_table() {
      this.get_data();
    },
    close_form() {
      if (this.$refs.form) {
        this.$refs.form.close_form();
      }
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
