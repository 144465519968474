<template>
  <div>
    <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
    <v-card v-if="data_loaded">
      <v-card-title>
        <v-icon x-large>approval</v-icon>
        <h1 class="ms-3">קופונים</h1>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          class="text-center"
          :headers="headers"
          :items="items"
          mobile-breakpoint="0"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr @click="edit_row(item)">
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.discount }}
              </td>
              <td>
                {{ item.start_date }}
              </td>
              <td>
                {{ item.end_date }}
              </td>
              <td>
                {{ item.times }}
              </td>
              <td>
                {{ item.customersTimes }}
              </td>
              <td>
                <v-chip
                  x-small
                  class="px-2"
                  :color="item.status ? 'success black--text' : 'error'"
                >
                  {{ item.status ? "פעיל" : "לא פעיל" }}
                </v-chip>
              </td>
            </tr>

            <tr v-if="item.form" class="grey lighten-3">
              <td colspan="10">
                <v-form :ref="`form_${item.id}`">
                  <v-row class="basic mt-2 mb-4 pt-3">
                    <v-col class="py-0">
                      <v-text-field
                        @keydown="input_change(item, 'name_exist')"
                        v-model="item.name"
                        background-color="white"
                        outlined
                        dense
                        :rules="[
                          (v) => !!v || 'שדה חובה',
                          !item.name_exist || 'כבר קיים',
                        ]"
                      >
                        <template slot="label">
                          <strong class="red--text">*</strong>
                          קוד קופון
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        outlined
                        dense
                        type="number"
                        v-model="item.discount"
                        :rules="[(v) => !!v || 'שדה חובה']"
                      >
                        <template slot="label">
                          <strong class="red--text">*</strong>
                          סכום קופון
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        outlined
                        dense
                        type="date"
                        v-model="item.start_date"
                        :rules="[(v) => !!v || 'שדה חובה']"
                      >
                        <template slot="label">
                          <strong class="red--text">*</strong>
                          מתאריך
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        outlined
                        dense
                        type="date"
                        v-model="item.end_date"
                        :rules="[
                          (v) => !!v || 'שדה חובה',
                          (v) => checkAfter(item) || 'after start',
                        ]"
                      >
                        <template slot="label">
                          <strong class="red--text">*</strong>
                          עד תאריך
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        outlined
                        dense
                        type="number"
                        v-model="item.times"
                      >
                        <template slot="label"> סה״כ שימוש </template>
                      </v-text-field>
                    </v-col>

                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        outlined
                        dense
                        type="number"
                        v-model="item.customersTimes"
                      >
                        <template slot="label"> סה״כ שימוש ללקוח </template>
                      </v-text-field>
                    </v-col>

                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        readonly
                        outlined
                        dense
                        label="סטטוס"
                        :value="item.status ? 'פעיל' : 'לא פעיל'"
                      >
                        <template slot="append">
                          <v-switch
                            v-model="item.status"
                            class="mt-0"
                            hide-details
                          ></v-switch>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0 d-flex justify-space-between">
                      <v-btn
                        depressed
                        height="40"
                        width="59%"
                        color="black white--text"
                        class="me-2"
                        :loading="item.save_loader"
                        :disabled="item.save_loader"
                        @click="save_update(item)"
                        v-if="$store.getters.permission('coupons edit')"
                        >שמירה</v-btn
                      >
                      <v-btn
                        v-if="$store.getters.permission('coupons delete')"
                        @click="delete_row(item.id)"
                        depressed
                        height="40"
                        width="35%"
                        color="white "
                      >
                        <v-icon color="black">delete_sweep </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-pagination
    class="mt-4"
      v-if="data_loaded"
      @input="change_page($event)"
      v-model="page"
      total-visible="7"
      :length="total"
      circle
    ></v-pagination>
    <DeleteDialog
      @delete_done="$emit('update_data')"
      @close_delete="delete_obj.delete_prop = false"
      :delete_obj="delete_obj"
    ></DeleteDialog>
  </div>
</template>

<script>
import DeleteDialog from "@/components/public/delete";
export default {
  name: "Table",
  props: ["data_loaded", "items", "page", "total"],
  data() {
    return {
      delete_obj: {
        delete_prop: false,
        delete_url: "coupons",
        title: "מחיקת קטגוריה",
        id: null,
      },
      headers: [
        {
          value: "name",
          text: "קוד קופון",
          align: "center",
        },
        {
          value: "discount",
          text: "סכום קופון",
          align: "center",
        },
        {
          value: "start_date",
          text: " מתאריך",
          align: "center",
        },
        {
          value: "end_date",
          text: "עד תאריך",
          align: "center",
        },
        {
          value: "times",
          text: "סה״כ שימוש ",
          align: "center",
        },
        {
          value: "customersTimes",
          text: "סה״כ שימוש ללקוח ",
          align: "center",
        },
        {
          value: "status",
          text: "סטטוס",
          align: "center",
        },
      ],
    };
  },
  components: {
    DeleteDialog,
  },
  methods: {
    input_change(item, type) {
      this.$set(item, `${type}`, false);
    },
    edit_row(item) {
      this.$emit("close_form");
      this.items.forEach((e) => {
        e.form = false;
      });
      item.form = true;
    },
    checkAfter(item) {
      return new Date(item.end_date) > new Date(item.start_date);
    },
    change_page(page) {
      this.$emit('updatePage', page)
    },
    save_update(item) {
      console.log(!item.save_loader);
      if (
        this.$refs[`form_${item.id}`].validate() &&
        !item.save_loader &&
        this.$store.getters.permission("coupons edit")
      ) {
        item.save_loader = true;

        this.$store
          .dispatch("public__request", {
            config: {
              url: `coupons/${item.id}`,
              method: "put",
            },
            data: item,
          })
          .then((res) => {
            item.save_loader = false;
            this.$emit("update_data");
          })
          .catch((err) => {
            item.save_loader = false;
            let errors = err.response.data.data;
            Object.keys(errors).forEach((e) => {
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this.$set(item, `${e}_exist`, true);
                }
              });
            });
          });
      }
    },
    delete_row(id) {
      this.delete_obj.id = id;
      this.delete_obj.delete_prop = true;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.basic {
  .col {
    flex: 0 0 20%;
    max-width: 20%;
    @media (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>
