<template>
  <v-card class="mt-10 mb-10">
    <v-card-title> הוספת מותג חדש</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form">
        <v-row class="basic mt-2">
          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              outlined
              dense
              v-model="item.name"
              @keydown="name_exist = false"
              :rules="[(v) => !!v || 'שדה חובה', !name_exist || 'כבר קיים']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                קוד קופון
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              outlined
              dense
              type="number"
              v-model="item.discount"
              :rules="[(v) => !!v || 'שדה חובה']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                סכום קופון
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              outlined
              dense
              type="date"
              v-model="item.start_date"
              :rules="[(v) => !!v || 'שדה חובה']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                מתאריך
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              outlined
              dense
              type="date"
              v-model="item.end_date"
              :rules="[
                (v) => !!v || 'שדה חובה',
                (v) => checkAfter() || 'after start',
              ]"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                עד תאריך
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              outlined
              dense
              type="number"
              v-model="item.times"
            >
              <template slot="label"> סה״כ שימוש </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              outlined
              dense
              type="number"
              v-model="item.customersTimes"
            >
              <template slot="label"> סה״כ שימוש ללקוח </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              readonly
              outlined
              dense
              label="סטטוס"
              :value="item.status ? 'פעיל' : 'לא פעיל'"
            >
              <template slot="append">
                <v-switch
                  v-model="item.status"
                  class="mt-0"
                  hide-details
                ></v-switch>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end px-4">
      <div class="d-flex">
        <v-btn
          @click="close_form()"
          x-large
          depressed
          height="40"
          color="black--text"
        >
          <v-icon>arrow_forward</v-icon>
        </v-btn>
        <v-btn
          @click="save()"
          :loading="save_loader"
          :disabled="save_loader"
          x-large
          depressed
          height="40"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      save_loader: false,
      name_exist: false,
      item: {
        name: "",
        discount: 0,
        start_date: null,
        end_date: null,
        times: null,
        status: true,
        customersTimes: null,
      },
    };
  },
  methods: {
    close_form() {
      if (this.save_loader) {
        this.$emit("update_table");
        this.save_loader = false;
      }
      this.$emit("close_form");
    },
    save() {
      if (this.$refs.form.validate() && !this.save_loader) {
        this.save_loader = true;

        this.$store
          .dispatch("public__request", {
            config: {
              url: "coupons",
              method: "POST",
            },
            data: this.item,
          })
          .then((res) => {
            console.log(res);
            this.close_form();
          })
          .catch((err) => {
            this.save_loader = false;
            let errors = err.response.data.errors;
            Object.keys(errors).forEach((e) => {
              console.log(e);
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this[`${e}_exist`] = true;
                }
              });
            });
            console.log(err.response);
          });
      }
    },
    checkAfter() {
      return new Date(this.item.end_date) > new Date(this.item.start_date);
    },
  },
};
</script>
